import {Box} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {SearchContext} from "../../../../../context/SearchContext";
import Web from "./components/Web/Web";
import Images from "./components/Images/Images";
import Videos from "./components/Videos/Videos";
import {getAds} from "../../../../../services/fetchers";
import Footer from "../Footer/Footer";
import loadScripts from "../../../../../services/loadScripts";

const Results = () => {
    const [results, setResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [isNext, setIsNext] = useState(true);
    const {currentPage, refreshStatesHandler, search, activeCategory} = useContext(SearchContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const resultRef = useRef<HTMLDivElement | null>(null);
    const footerRef = useRef<HTMLDivElement | null>(null);

    const showResults = () => {
        if (resultRef.current) {
            resultRef.current.style.display = "block";
        }
        if (footerRef.current) {
            footerRef.current.style.display = "block";
        }
        loadScripts();
    }

    const getUrl = () => {
        const queryParams = {keywords: search, page: currentPage};
        let res = "/search";
        if (activeCategory === "Images") {
            res += "/images/";
        } else if (activeCategory === "Videos") {
            res += "/videos/";
        } else {
            res += "/text/"
        }
        return `${res}?${new URLSearchParams(queryParams as any).toString()}`;
    }

    const getAnswers = async () => {
        const url = getUrl();
        try {
            setLoading(true);
            const res = await axios.get(url);
            setResults(res.data.content);
            setIsNext(res.data.next);
        } catch (error) {
            console.error("error", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const query = searchParams.get("q")
        if (query) {
            sessionStorage.setItem("q", query);
            setSearchParams({});
            refreshStatesHandler();
        }
    }, [])

    useEffect(() => {
        if (search) {
            (async () => {
                const channel = searchParams.get("channel") || "";
                getAds(activeCategory, search, search, undefined, undefined, channel, showResults);
                await getAnswers();
            })()
        }
    }, [search, currentPage, activeCategory])

    const getResultsComponent = useCallback(() => {
        switch (activeCategory) {
            case "Images":
                return <Images results={results} isNext={isNext} />
            case "Videos":
                return <Videos results={results} isNext={isNext} />
            case "Web":
            default:
                return (
                    <Box sx={{ml: {xs: "0", sm: "170px"}}}>
                        <Web results={results} isNext={isNext} />
                    </Box>
                )
        }
    }, [activeCategory, results])

    return (
        <Box sx={{flex: "auto", display: "flex", flexDirection: "column"}}>
            <Box sx={{margin: {xs: "165px 0 0 0", sm: "112px 0 0 170px"}, flex: "1"}}>
                {activeCategory === "Web" &&
                    <Box sx={{p: {xs: "0.25rem 1rem 0 1rem", sm: ".5rem 0 0 0"}, maxWidth: {xs: "100%", sm: "630px"}, width: "100%"}}>
                        <div id="afscontainer1" style={{width: "100%", marginBottom: "1rem"}}></div>
                    </Box>
                }
            </Box>
            <Box ref={resultRef} sx={{display: "none"}}>
                {getResultsComponent()}
            </Box>
            <Box ref={footerRef} sx={{display: "none"}}>
                <Footer />
            </Box>
        </Box>
    )
}

export default Results;