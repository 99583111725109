import React from "react";
import {Box} from "@mui/material";
import {Link} from "react-router-dom";
import Logo from "../../../../../components/Logo/Logo";
import AddExtButton from "../../../../../components/ChromeExtensionAd/AddExtButton";

const Footer = () => (
    <Box sx={{mt: "1rem", p: "1.5rem", background: "var(--background)", display: "flex",
        justifyContent: "space-between", alignItems: "center", flexDirection: {xs: "column", sm: "row"}, gap: ".5rem"}}
    >
        <Logo size="small" />
        <Link to="/privacy">
            Privacy Policy
        </Link>
        <AddExtButton />
    </Box>
)

export default Footer;