import {createContext, FC, ReactChildren, ReactNode, useEffect, useState} from "react";
import {getMe} from "../services/fetchers";
import Loading from "../components/Loading/Loading";

interface AuthContextInterface {
    me: Record<string, any>,
    loading: boolean,
    isLoggedIn: boolean,
    setIsLoggedIn: (value: boolean) => void,
    setMe: (value: Record<string, any>) => void
}

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

type AuthProviderType = {
    children: ReactNode
}

const AuthProvider: FC<AuthProviderType> = ({children}) => {
    const [me, setMe] = useState<Record<string, any>>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        if (Object.keys(me).length === 0) {
            (async () => {
                try {
                    setMe(await getMe());
                    setIsLoggedIn(true);
                } catch (err) {
                    setIsLoggedIn(false);
                    console.error("You are not authorized.");
                } finally {
                    setLoading(false);
                }
            })()
        }
    }, [isLoggedIn])

    if (loading) {
        return <Loading />
    }

    return (
        <AuthContext.Provider value={{me, setMe, loading, isLoggedIn, setIsLoggedIn}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;