import {createContext, FC, ReactChildren, ReactNode, useState} from "react";

interface SearchContextInterface {
    currentPage: number;
    setCurrentPage(page: number): void;
    search: string;
    setSearch(search: string): void;
    activeCategory: string;
    setActiveCategory(category: string): void;
    refreshStatesHandler(): void;
}

export const SearchContext = createContext<SearchContextInterface>({} as SearchContextInterface);

type SearchProviderType = {
    children: ReactNode
}

const SearchProvider: FC<SearchProviderType> = ({children}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, setSearch] = useState<string>(sessionStorage.getItem("q") || "");
    const [activeCategory, setActiveCategory] = useState(sessionStorage.getItem("ia") || "Web");

    const refreshStatesHandler = () => {
        const p = Number(sessionStorage.getItem("p") || 1);
        const q = sessionStorage.getItem("q") || "";
        const tab = sessionStorage.getItem("ia") || "Web";
        if (p !== currentPage) {
            setCurrentPage(p);
        }
        if (q !== search) {
            setSearch(q);
        }
        if (activeCategory !== tab) {
            setActiveCategory(tab);
        }
    }

    return (
        <SearchContext.Provider value={{refreshStatesHandler, currentPage, setCurrentPage, search, setSearch, activeCategory, setActiveCategory} as any}>
            {children}
        </SearchContext.Provider>
    )
}

export default SearchProvider;