import {Navigate, useNavigate, useSearchParams,} from "react-router-dom";
import {Box} from "@mui/material";
import Header from "./modules/Header/Header";
import Results from "./modules/Results/Results";
import SearchProvider from "../../../context/SearchContext";
import Footer from "./modules/Footer/Footer";

const SearchPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const q = sessionStorage.getItem("q") || "";
    const query = searchParams.get("q");
    const navigate = useNavigate();

    if (query) {
        sessionStorage.setItem("q", query);
        navigate("/search");
    }

    if (!q && !query) {
        return <Navigate to="/" replace />
    }

    return (
        <SearchProvider>
            <Box component="main" sx={{height: "100%"}}>
                <Box sx={{display: "flex", flexDirection: "column", position: "relative", width: "100%", overflowX: "hidden", height: "100%"}}>
                    <Header />
                    <Results />
                </Box>
            </Box>
        </SearchProvider>
    )
}

export default SearchPage;