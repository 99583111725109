import {Navigate, Outlet} from "react-router-dom";
import {FC, useContext} from "react";
import {PrivateRouteType} from "./type";
import {AuthContext} from "../context/AuthContext";

const PrivateRoute: FC<PrivateRouteType> = ({redirectPath="/settings/buyer/sign-in", children=null}) => {
    const {me, loading} = useContext(AuthContext);

    if (!loading && Object.keys(me).length === 0) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</> || <Outlet />;
};

export default PrivateRoute;