import React, {useContext, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import SearchInputForm from "../../../../../components/SearchInput/SearchInput";
import SearchIcon from "./Components/Icons/SearchIcon";
import ImagesIcon from "./Components/Icons/ImagesIcon";
import VideoIcon from "./Components/Icons/VideoIcon";
import {SearchContext} from "../../../../../context/SearchContext";
import Logo from "../../../../../components/Logo/Logo";

const tabs = [
    {
        label: "Web",
        Icon: SearchIcon
    },
    // {
    //     label: "Images",
    //     Icon: ImagesIcon
    // },
    // {
    //     label: "Videos",
    //     Icon: VideoIcon
    // }
];

const Header = () => {
    const {activeCategory, refreshStatesHandler} = useContext(SearchContext);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        const main: HTMLElement | null = document.querySelector("main");
        if (main && main.firstElementChild) {
            (main.firstElementChild as HTMLElement).style.overflowX = "initial";
        }
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const {scrollY} = window;

            if (scrollY > 0 && scrollY > prevScrollY) {
                setIsHidden(true);
            } else {
                setIsHidden(false);
            }

            setPrevScrollY(scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollY]);

    const handleChange = (tab: string) => {
        sessionStorage.setItem("ia", tab);
        sessionStorage.setItem("p", "1");
        refreshStatesHandler();
    };

    return (
        <div style={{overflowX: "hidden"}}>
            <Box component="header"
                sx={{
                    transform: `translateY(${isHidden ? "-100%" : "0"})`,
                    transition: "transform 0.3s",
                    position: "fixed", width: "100%", top: "0", zIndex: "123",
                    background: "var(--background)", height: {xs: "165px", sm: "112px"}, p: {xs: "1rem", sm: "1rem 1.5rem 0 1.5rem"},
                    display: "flex", gap: {xs: ".5rem", sm: "1.5rem"}, flexDirection: {xs: "column", sm: "row"}, alignItems: {xs: "center", sm: "initial"}
                }}
            >
                <Logo size="middle" />
                <Box sx={{display: "flex", flexDirection: "column", gap: {xs: ".5rem", sm: "1rem"}, width: "100%"}}>
                    <Box sx={{width: "100%", maxWidth: "602px"}}>
                        <SearchInputForm />
                    </Box>
                    <Box sx={{display: "flex", gap: ".625rem"}}>
                        { tabs.map((tab, index) => {
                            const active = tab.label === activeCategory;
                            return (
                                <Box
                                    key={index}
                                    sx={{cursor: "pointer", height: {xs: "2.5rem", sm: "2rem"}, p: {xs: "8px 6px 0 6px", sm: "0 6px"},
                                        borderBottom: active ? "2px solid var(--blue)" : "none",
                                        display: "flex", justifyContent: "center", gap: "6px"
                                    }}
                                    onClick={() => handleChange(tab.label)}
                                >
                                    <Box sx={{pt: "2px"}}>
                                        <tab.Icon active={active} />
                                    </Box>
                                    <Typography sx={{fontSize: "13px", fontWeight: "500", color: active ? "var(--blue)" : "var(--black)"}}>
                                        {tab.label}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default Header;