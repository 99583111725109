import React, {Suspense} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "../pages/client/MainPage";
import SearchPage from "../pages/client/SearchPage/SearchPage";
import PrivacyPolicy from "../components/PrivacyPolicy";
import PrivateRoute from "../routes/PrivateRoute";
import AuthProvider from "../context/AuthContext";
import Loading from "../components/Loading/Loading";

const MainLayout = React.lazy(() => import("../layouts/BuyerMainLayout"));
const Dashboard = React.lazy(() => import("../pages/buyer/Dashboard/Dashboard"));
const SignIn = React.lazy(() => import("../pages/buyer/SignIn/SignIn"));
const RelatedSearch = React.lazy(() => import("../pages/buyer/RelatedSearch/RelatedSearch"));

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        { window.location.pathname.includes("settings") && (
            <AuthProvider>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path="/settings/buyer" element={
                            <PrivateRoute>
                                <MainLayout />
                            </PrivateRoute>}
                        >
                            <Route index element={<Dashboard />} />
                            <Route path="/settings/buyer/create-related-search" element={<RelatedSearch mode="create" />} />
                            <Route path="/settings/buyer/view/:id" element={<RelatedSearch mode="view" />} />
                            <Route path="/settings/buyer/edit/:id" element={<RelatedSearch mode="edit" />} />
                        </Route>
                        <Route path="/settings/buyer/*" element={<h1 style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>404 Page not Found</h1>} />
                        <Route path="/settings/buyer/sign-in" element={<SignIn />} />
                    </Routes>
                </Suspense>
            </AuthProvider>
        )}
    </BrowserRouter>
)

export default App;
