import {Link} from "react-router-dom";
import React, {FC} from "react";
import {Box} from "@mui/material";
import LogoPNG from "../../assets/images/croxy.png";

const Logo: FC<{size: string}> = ({ size="small" }) => {
    let sizes;
    if (size === "small") {
        sizes = {
            logo: {
                width: "73px",
                height: "28px",
            }
        }
    } else if (size === "middle") {
        sizes = {
            logo: {
                width: "128px",
                height: "44px"
            }
        }
    } else {
        sizes = {
            logo: {
                width: "142px",
                height: "45px"
            }
        }
    }
    return (
        <Link to="/" style={{color: "var(--black)"}}>
            <Box sx={sizes.logo}>
                <img src={LogoPNG} alt="Logo" style={{width: "100%", height: "100%", objectFit: "contain"}}/>
            </Box>
        </Link>
    )
}

export default Logo;