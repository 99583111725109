import {FC} from "react";

const SearchIcon: FC<{active?: boolean}> = ({active= false}) => {
    const color = active ? "var(--blue)" : "#3B435B";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.15385 11.0577C8.86217 11.0577 11.0577 8.86217 11.0577 6.15385C11.0577 3.44553 8.86217 1.25 6.15385 1.25C3.44553 1.25 1.25 3.44553 1.25 6.15385C1.25 8.86217 3.44553 11.0577 6.15385 11.0577Z" stroke={color} strokeWidth="1.5"/>
            <path d="M9.8457 9.84619L13.9995 14" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export default SearchIcon;
