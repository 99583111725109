import axios from "axios";
import {useNavigate} from "react-router-dom";
import debounce from "lodash.debounce";
import {Controller, useForm} from "react-hook-form";
import {Autocomplete, Box, Divider, IconButton, InputAdornment, TextField} from "@mui/material";
import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {ReactComponent as SearchIcon} from "../../assets/icons/search.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import {SearchContext} from "../../context/SearchContext";

const SearchInputForm: FC<{size?: string}> = ({size="big"}) => {
    const [suggestedSearch, setSuggestedSearch] = useState([]);
    const {refreshStatesHandler, search} = useContext(SearchContext);
    const initialSearchValue: string = search || "";
    const {
        handleSubmit,
        control,
        watch,
        setValue,
    } = useForm({
        defaultValues: {search: initialSearchValue}
    });
    const navigate = useNavigate();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const searchValue = watch("search");

    const getSuggestedSearch = async (searchValue: string) => {
        try {
            const url = `/search/suggestions/?${new URLSearchParams({keywords: searchValue}).toString()}`
            const res = await axios.get(url);
            setSuggestedSearch(res.data[1]);
        } catch (e) {
            console.error("Failed to search")
        }
    }

    const debounceSuggestedSearch = useCallback(
        debounce(async searchValue => {
            await getSuggestedSearch(searchValue);
        }, 300), [])

    const onSubmit = (data: any) => {
        let res;
        if (Object.hasOwn(data, "search") && typeof data === "object") {
            res = data.search;
        } else {
            res = data.toString();
        }
        if (!res || res === search) {
            return;
        }
        sessionStorage.setItem("q", res);
        sessionStorage.setItem("p", "1");
        if (typeof refreshStatesHandler === "function") {
            refreshStatesHandler();
        }
        navigate({
            pathname: "/search",
        })
    }

    useEffect(() => {
        if (searchValue && searchValue.length > 1 && !firstLoad) {
            // debounceSuggestedSearch(searchValue)
        } else {
            setSuggestedSearch([]);
            setFirstLoad(false);
        }
    }, [searchValue])

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
            <Controller
                name="search"
                control={control}
                defaultValue={searchValue}
                render={({ field }) => (
                    <Autocomplete
                        onClose={(e, reason) => {
                            if (reason === "selectOption") {
                                if (e.type === "keydown") {
                                    setTimeout(() => {
                                        onSubmit((e.target as HTMLInputElement).value);
                                    }, 100);
                                } else {
                                    onSubmit((e.target as HTMLElement).innerText);
                                }
                            }
                        }}
                        freeSolo
                        id="free-solo-2-demo"
                        noOptionsText=""
                        filterOptions={item => item}
                        options={suggestedSearch}
                        disableClearable={false}
                        value={field.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                placeholder="Search in Croxy"
                                value={field.value || ""}
                                onChange={e => {
                                    field.onChange(e.target.value);
                                    if (e.target.value === "") {
                                        setSuggestedSearch([]);
                                    }
                                }}
                                sx={{
                                    ".MuiOutlinedInput-notchedOutline": {
                                        border: "1px solid var(--gray)",
                                    },
                                    ".MuiOutlinedInput-root": {
                                        flexWrap: "nowrap",
                                    },
                                    ":hover": {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderColor: "var(--blue) !important",
                                        },
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        borderRadius: "1.5rem",
                                        height: size === "big" ? "3rem" : "1.75rem",
                                        flexWrap: "nowrap",
                                        pr: "15px !important",
                                        backgroundColor: "#fff",
                                    },
                                    endAdornment: (
                                        <Box sx={{display: "flex", gap: ".5rem", alignItems: "center"}}>
                                            { searchValue &&
                                                <IconButton sx={{p: "0"}} onClick={() => {
                                                    setValue("search", "");
                                                    field.onChange("");
                                                    setSuggestedSearch([]);
                                                }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            }
                                            <Divider orientation="vertical" flexItem />
                                            <InputAdornment position="start">
                                                <IconButton sx={{p: "0"}}
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        </Box>
                                    ),
                                }}
                            />
                        )}
                    />
                )}
            />
        </form>
    )
}

export default SearchInputForm;