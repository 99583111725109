import {FC, useContext} from "react";
import {Link} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import ErrorFavicon from "../../../../../../../assets/icons/error-fav.svg";
import {SearchContext} from "../../../../../../../context/SearchContext";

export const paddingActionBtnStyle = {
    textTransform: "none", borderRadius: "1.5rem", color: "var(--blue)",
    background: "var(--padding-btn)", p: ".5rem 1.5rem", fontSize: "14px", fontWeight: "500",
    ":hover": {background: "var(--padding-btn)", color: "var(--blue)"},
}

const WebResults: FC<any> = ({ results, isNext }) => {
    const {currentPage, refreshStatesHandler, search, activeCategory} = useContext(SearchContext);

    const changePageHandler = (side: string = "next") => {
        let value: number = 1;
        if (side === "next") {
            if (isNext) {
                value = currentPage + 1;
            }
        } else if (currentPage > 1) {
            value = currentPage - 1;
        }
        sessionStorage.setItem("p", String(value));
        refreshStatesHandler()
    }


    return (
        <Box sx={{p: {xs: "0.25rem 1rem", sm: ".5rem 0 1rem 0"}, maxWidth: {xs: "100%", sm: "630px"}, width: "100%"}}>
            <Typography sx={{fontSize: ".75rem", mb: "1rem"}}>
                Web results
            </Typography>
            <Box sx={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                { results && results.length > 0 &&
                    results.map((result: any, index: number) => (
                        <Box key={index}>
                            <Box sx={{display: "flex", gap: ".25rem", mb: ".25rem", alignItems: "center"}}>
                                <img src={result.favicon} alt="web site favicon"
                                    onError={e => {
                                        (e.target as any).src = ErrorFavicon
                                    }}
                                    style={{width: "1rem", height: "1rem"}}
                                />
                                <Link to={result.href} target="_blank" style={{overflow: "hidden"}}>
                                    <Typography sx={{fontSize: "13px", color: "var(--href)", cursor: "pointer",
                                        maxWidth: {xs: "auto", sm: "455px"}, overflow: "hidden", textOverflow: "ellipsis", marginRight: {xs: "0", sm: ".5rem"}, whiteSpace: "nowrap",
                                        ":hover": {
                                            textDecoration: "underline"
                                        }
                                    }}>
                                        {result.href}
                                    </Typography>
                                </Link>
                            </Box>
                            <Link to={result.href} target="_blank" style={{overflow: "hidden"}}>
                                <Typography sx={{color: "var(--dark-blue)", fontSize: "1.125rem", mb: ".25rem", lineHeight: "1.5rem", cursor: "pointer",
                                    ":hover": {
                                        textDecoration: "underline"
                                    }
                                }}>
                                    {result.title}
                                </Typography>
                            </Link>
                            <Typography sx={{display: "-webkit-flex", WebkitLineClamp: "2", fontSize: "13px", WebkitBoxOrient: "vertical",
                                lineHeight: "1.25rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal", maxHeight: "3em"}}
                            >
                                {result.body}
                            </Typography>
                        </Box>
                    ))
                }
            </Box>
            <Box sx={{display: "flex", gap: "1rem", mt: "1rem", p: ".5rem 0", alignItems: "center", justifyContent: {xs: "center", sm: "start"}}}>
                { currentPage > 1 &&
                    <Button sx={paddingActionBtnStyle}
                        onClick={() => changePageHandler("prev")}
                    >
                        Prev
                    </Button>
                }
                <Button sx={{minWidth: "auto", width: "34px", height: "34px", background: "var(--blue)",
                    borderRadius: "50%", color: "#fff", fontSize: "14px",
                    ":hover": {background: "var(--blue)", color: "#fff"},
                }}>
                    {currentPage}
                </Button>
                { isNext &&
                    <Button sx={paddingActionBtnStyle}
                        onClick={() => changePageHandler("next")}
                    >
                        Next
                    </Button>
                }
            </Box>
        </Box>
    )
}

export default WebResults;