export default function loadScripts() {
    // FB
    const fbString = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '6334663469976877');
      fbq('track', 'PageView');`;
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.append(document.createTextNode(fbString));
    document.body.append(s);
    const gtmString = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WFF2ZQX5');`;
    const g = document.createElement("script");
    g.type = "text/javascript";
    g.async = true;
    g.append(document.createTextNode(gtmString));
    document.body.append(g);
}