import {useEffect} from "react";
import {Box, Container, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import ChromeExtensionAd from "../../components/ChromeExtensionAd/ChromeExtensionAd";
import SearchInputForm from "../../components/SearchInput/SearchInput";
import Logo from "../../components/Logo/Logo";

const MainPage = () => {

    useEffect(() => {
        sessionStorage.clear();
    }, [])

    return (
        <Container component="main" sx={{height: "100%", position: "relative"}}>
            <Box sx={{p: {xs: "1rem", sm: "23% 0 48px 0"}, height: "100%", display: "flex", alignItems: "center", justifyContent: {xs: "center", sm: "space-between"}, flexDirection: "column"}}>
                <Box sx={{display: "flex", flexDirection: "column", gap: "1.5rem", alignItems: "center", width: "100%", maxWidth: {xs: "534px", sm: "534px"}}}>
                    <Logo size="large" />
                    <SearchInputForm />
                    <ChromeExtensionAd />
                </Box>
                <Link to="/privacy" style={{color: "var(--black)", position: "absolute", bottom: "3%", textDecoration: "none", fontSize: "14px"}}>
                    Privacy Policy
                </Link>
            </Box>
        </Container>
    )
}

export default MainPage;