import axios from "axios";

const pubId = "partner-pub-8122604857359730";

export const getAds = (activeCategory = "Web", search="", terms="", lang="en",
    styleId: string | null ="6813868572", channel: string = "", adsLoadedCallback=() => {}
) => {
    const {_googCsa} = window;
    if (_googCsa && activeCategory === "Web") {
        const pageOptions = {
            "pubId": pubId,
            "styleId": styleId || "6813868572",
            "channel": channel,
            "query": search,
            "adsafe": "low",
            "adtest": "off",
            "linkTarget": "_blank",
            "hl": lang,
            "personalizedAds": true,
            "resultsPageBaseUrl": "http://localhost:3000/search?",
            "terms": terms || search,
            "width": `${window.innerWidth > 600 ? 640 : window.innerWidth - 32}px`,
            "resultsPageQueryParam": "q",
            "adLoadedCallback" : (containerName: string, adsLoaded: boolean, isExperimentVariant: boolean, callbackOptions: Function): any => {
                adsLoadedCallback();
            }
        };
        const adblock2 = {
            "container": "afscontainer1",
            "number": 3,
        };
        _googCsa("ads", pageOptions, adblock2);
    }
}

export const getRelatedSearches = (search="", terms="", related_search_count=4,
    lang="en", styleId: string | null ="6922556780", channel="", callback=() => {}
) => {
    const {_googCsa} = window;
    if (_googCsa) {
        const pageOptions = {
            "pubId": pubId,
            "styleId": styleId || "6922556780",
            "channel": channel,
            "linkTarget": "_top",
            "query": search,
            "adsafe": "low",
            "adtest": "off",
            "hl": lang,
            "personalizedAds": true,
            "resultsPageBaseUrl": `https://croxy.io/s?lang=${lang}&style_id=${styleId || "6922556780"}`,
            "terms": terms || search,
            "width": `${window.innerWidth > 600 ? 442 : window.innerWidth - 32}px`,
            "resultsPageQueryParam": "q",
            "adLoadedCallback" : (): any => {
                callback();
            }
        };
        const rsblock1 = {
            "container": "relatedsearches1",
            "relatedSearches": related_search_count
        };
        _googCsa("ads", pageOptions, rsblock1);
    }
}

export const getMe = async () => {
    try {
        const res = await axios.get("/me/");
        return await res.data;
    } catch (err: any) {
        throw new Error("User is unauthorized!");
    }
}
