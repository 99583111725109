import {FC, useContext} from "react";
import {Link} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import {SearchContext} from "../../../../../../../context/SearchContext";
import {paddingActionBtnStyle} from "../Web/Web";

const Images: FC<any> = ({ results, isNext }) => {
    const {currentPage, refreshStatesHandler} = useContext(SearchContext);

    const changePageHandler = (side: string = "next") => {
        let value: number = 1;
        if (side === "next") {
            if (isNext) {
                value = currentPage + 1;
            }
        } else if (currentPage > 1) {
            value = currentPage - 1;
        }
        sessionStorage.setItem("p", String(value));
        refreshStatesHandler()
    }

    return (
        <Box sx={{mt: {xs: "152px", sm: "112px"}, p: "10px", width: "100%", minHeight: {xs: "calc(100vh - 152px)", sm: "calc(100vh - 112px)"},
            display: "flex", flexDirection: "column", justifyContent: "space-between"}}
        >
            <Box sx={{display: "grid", gridAutoRows: "262px",
                gridTemplateColumns: {
                    xs: "repeat(2, 1fr)",
                    sm: "repeat(6, 1fr)",
                    lg: "repeat(8, 1fr)",
                }}}
            >
                { results && results.length > 0 &&
                    results.map((result: any, index: number) => (
                        <Link to={`${result.url}`} target="_blank">
                            <Box key={index} sx={{p: ".5rem", display: "flex", flexDirection: "column", gap: ".25rem", cursor: "pointer"}}>
                                <Box sx={{width: "100%", height: "200px", minHeight: "200px", maxHeight: "200px", background: "var(--light-gray)",
                                    borderRadius: "1rem", overflow: "hidden",
                                    ":hover": {
                                        boxShadow: "0px 0px 10px 4px rgba(34, 60, 80, 0.2)"
                                    }}}
                                >
                                    <img src={result.thumbnail} alt="item" style={{width: "100%", height: "100%"}}
                                        onError={e => {
                                            (e.target as any).src = result.image
                                        }}
                                    />
                                </Box>
                                <Typography sx={{p: "0 .5rem", fontSize: "13px", color: "var(--black)", fontWeight: "500",
                                    overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
                                    maxWidth: "165px", lineHeight: "1.5rem", height: "1.5rem"
                                }}>
                                    {result.title}
                                </Typography>
                                <Typography sx={{p: "0 .5rem", fontSize: "12px", color: "var(--blue)",
                                    overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
                                    maxWidth: "165px", lineHeight: "1.5rem", height: "1.5rem",
                                    ":hover": {
                                        textDecoration: "underline"
                                    }
                                }}>
                                    {result.url}
                                </Typography>
                            </Box>
                        </Link>
                    ))
                }
            </Box>
            <Box sx={{display: "flex", gap: "1rem", m: "1.5rem 0", alignItems: "center", justifyContent: {xs: "center", sm: "center"}}}>
                { currentPage > 1 &&
                    <Button sx={paddingActionBtnStyle}
                        onClick={() => changePageHandler("prev")}
                    >
                        Prev
                    </Button>
                }
                <Button sx={{minWidth: "auto", width: "34px", height: "34px", background: "var(--blue)",
                    borderRadius: "50%", color: "#fff", fontSize: "14px",
                    ":hover": {background: "var(--blue)", color: "#fff"},
                }}>
                    {currentPage}
                </Button>
                { isNext &&
                    <Button sx={paddingActionBtnStyle}
                        onClick={() => changePageHandler("next")}
                    >
                        Next
                    </Button>
                }
            </Box>
        </Box>
    )
}

export default Images;