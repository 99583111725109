import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useState} from "react";
import {Link} from "react-router-dom";

const AddExtButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <>
            <Link
                target="_blank"
                to="https://chrome.google.com/webstore/detail/croxy-search/chiiadkfompgkbfehmbclheciipdjjbl"
                // style={{borderRadius: "1rem"}}
                // variant="outlined"
                // sx={{p: "3px 8px", background: "var(--green-background)", borderRadius: "1.5rem", width: {xs: "100%", sm: "130px"}, border: "none", textTransform: "none", color: "var(--green)",
                //     ":hover": {
                //         color: "#fff",
                //         background: "var(--green)",
                //         border: "none",
                //     }
                // }}
            >
                Add to Chrome
            </Link>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Notification
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The extension is currently at the validation stage, soon you will be able to add the Profi Ninja search engine to the main one.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddExtButton;