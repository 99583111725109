import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/global.css";
import App from "./app/App";
import {BASE_URL} from "./services/constants";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <App />
);
