const PrivacyPolicy = () => (
    <div style={{
        padding: ".5rem"
    }}>
        <div className="privacy-policy-wrap">
            <h2 className="sub-header">
                <span>Privacy</span>
                Policy
            </h2>
            <p>
                This Privacy Policy defines the information collection, utilization, and distribution practices of <span className="domain-name"></span>
                , its associates, subsidiaries, and partners, (collectively referred here as &quot;the COMPANY&quot;) and is applicable to the COMPANY&apos;s collection, utilization, storage and, disclosure of information by the COMPANY on its <span className="domain-name"></span>
                . If you register as a Member and agree to all the terms and conditions, then you may select the &quot;Accept&quot; button. In case you do not want to be bound by any of these terms and conditions, then select &quot;DO NOT ACCEPT&quot; button and the registration process will discontinue. If you select &quot;DO NOT ACCEPT&quot; or otherwise do not want to be bound by the terms and conditions of this Privacy Policy, then you cannot register as a Member. This Privacy Policy is incorporated into and is subject to the <span className="domain-name"></span>
                terms of use. Before you select &quot;ACCEPT&quot; and continue the registration process, it is recommended to save a copy of this policy or take a print out of the same for personal records. If you are not willing to register but want to browse our website as a visitor, your continued use of the website will be deemed an acceptance of these terms. In case you do not agree to this policy, please do not provide any information or use any of the products or services provided on any of the websites mentioned or referred to in this Privacy Policy.
            </p>
            <p>
                By browsing this website or any other affiliated website of <span className="domain-name"></span>
                , including all of its subsidiaries, divisions, and other related companies (Collectively, the &quot;<span className="domain-name"></span>
                &quot;), you agree to be bound by the terms and conditions of this Privacy Policy, whether you are a &quot;Member&quot;, which suggests that you have registered with any of the <span className="domain-name"></span>
                , or are a &quot;Visitor&quot;, which means that you only browse the website. The term &quot;User&quot; here refers to a Member or Visitor. &quot;You&quot; refers to you as a Member or a Visitor.
            </p>
            <p>
                The COMPANY may change any term of this Privacy Policy at any time and for any reason in the wake of providing notification to you, which may be done by posting notification of the change to the <span className="domain-name"></span>
                or by sending you an email of the change to the Privacy Policy. In case any of these changes to the Privacy Policy are unsuitable to you, you must discontinue the use of <span className="domain-name"></span>
                . The COMPANY is not responsible for the collection or privacy practices of the third-party companies or its clients or applications. This Privacy Policy is strictly restricted to the information practices related to our website only. This website also has Linked Sites which are not owned or operated by us. Please be aware that the working of such Linked Sites is not our responsibility and you must read the respective privacy policies of these Linked Sites before proceeding further.
            </p>
            <h4>Personal Information Collected on This Website</h4>
            <p>
                Visitors - None of the personally identifiable information is collected on <span className="domain-name"></span>
                from our Visitors who browse the <span className="domain-name"></span>
                for information. Members - Personally identifiable data gathered on a <span className="domain-name"></span>
                is collected on our &quot;Sign In&quot; pages. These fields include name, phone number, address, email address, age, gender, and other information needed for the system to offer you with the relevant information responsive to your request.
            </p>
            <p>
                The submitted personal information of a Member to the <span className="domain-name"></span>
                remains the property of the Member. However, by submitting that information to the <span className="domain-name"></span>
                , you, as a Member, provide the COMPANY with the right to utilize that data for the below-mentioned disclosed purposes.
            </p>
            <h4>How We May Use Your Personal Information</h4>
            <p>The COMPANY may use the personal information of the Users to provide the requested services along with displaying customized advertising and content. We may disclose, sell, or lease a Member&apos;s personal information to third-party businesses and other units affiliated with the COMPANY. In addition, we may collaborate with other businesses to bring opportunities to our Members. This suggests that we may utilize personal information to provide promotional offers to Members by means of telephone marketing, email advertising, direct mail marketing, package stuffers, and online banner advertising, among other possible uses (collectively known as &quot;Promotions&quot;). Third-party businesses may comprise direct marketing services and application providers, including lookup and reference, data enhancement, suppression, and validation. We may also use such data for content improvement and feedback. The COMPANY may use personal information for research, auditing, and analysis to manage and enhance our services and technologies. We may also reveal personal information to the extent necessary if the COMPANY is acquired or sold or otherwise deemed necessary or appropriate.</p>
            <h4>Usage Tracking and Log Files</h4>
            <p>
                The COMPANY tracks all the information associated with the e-mailers and web pages that are viewed by the Members, as well as clicked hyperlinks by the Members while visiting or viewing our websites. Member usage is tracked and monitored by Log Files. We collect the IP (Internet Protocol) address of the visitor to track the problems of our server and to manage the <span className="domain-name"></span>
                . The IP address is a number allotted to your computer while you use the Internet. Your IP address also identifies you during a browsing session to collect comprehensive demographic data.
            </p>
            <h4>Security</h4>
            <p>
                The COMPANY utilizes industry-standard security measures to protect the misuse, loss, and alteration of the information under its practical control. Although we make significant efforts to store the collected information on <span className="domain-name"></span>
                in a safe operating environment, which is not available to the public, we cannot assure complete security. Furthermore, even though we make every effort to protect the security and integrity of our networks and systems, we cannot assure that our security measures will prevent third-party &quot;hackers&quot; from unlawfully procuring this information.
            </p>
            <h4>Cookies, Web Beacons, and Other Information Collected Using Technology</h4>
            <p>Web beacon and cookies technology is currently being used by the COMPANY to associate specific Internet-related information about an individual with the information about the individual in the COMPANY&apos;s database.</p>
            <h4>Cookies</h4>
            <p>
                Cookies are created by a web server and these are little pieces of data distributed through a web browser and stored on the computer. Cookies provide a means of website the visitor visits to track all the online preferences and patterns of the visitor. These cookies also identify the visitor as a return visitor. Cookies help in personalizing web experiences of visitors so that you as a visitor do not need to re-enter your personal information to a given website on each visit. (Visitors who use the site <span className="domain-name"></span>
                need to accept cookies to utilize every function and feature of the site).
            </p>
            <h4>Web Beacons</h4>
            <p>A programming code, web beacons are used to display an image on the web page as well as to transfer a unique user identification (usually in the form of cookies) to a database and associate the user with earlier-acquired information about the visitor. This allows tracking of certain websites visited by an individual. Web beacons determine services and products that an individual may be interested in and track the behavioral habits for marketing.</p>
            <h4>No Information Collected from the Children</h4>
            <p>
                <span className="domain-name"></span>
                are websites for the general audience and are intended for use by adults. The COMPANY does not intentionally solicit, attract or collect personal information about or from children. The <span className="domain-name"></span>
                are not intended for the children and the COMPANY does not want to collect any personal information from Users below the age of 13 years. The COMPANY understands the safety of children and strongly recommends that parents must read and review this Privacy Policy carefully to understand the terms and conditions.
            </p>
            <h4>Legal Proceedings</h4>
            <p>The COMPANY may reveal a Member&apos;s personally recognizable data pursuant to a subpoena or other administrative or judicial order, required by the law, or, at our sole discretion, where we believe necessary to protect the safety of the public or any individual or to prevent the exploitation of any third party or the rights of the COMPANY.</p>
            <h4>Wireless Addresses</h4>
            <p>In case the Member provides a wireless e-mail address to the COMPANY, they agree to receive messages on the provided e-mail address from the COMPANY or its business associates (unless the Member desires not to receive these messages by unsubscribing to such option on this policy). Member understands that a Member&apos;s wireless carrier&apos;s standard rates apply to these messages.</p>
            <h4>Financial Information</h4>
            <p>We may, in certain cases, collect bank account numbers, credit card numbers, and other related information, such as the expiration date of the credit card (&quot;Financial Information&quot;). This Financial Information is submitted to us and is encoded and secured with SSL encryption software. We will use this Financial Information for purposes of processing and completing the purchase transaction. Moreover, the Financial Information will be revealed to third parties only as a necessity to complete the purchase transaction. This information will not be distributed or sold to third parties in any way.</p>
            <h4>Email and Unsubscribe Policy</h4>
            <p>As detailed above, the COMPANY uses a Member&apos;s personal information for promotions by sending emails to individuals and companies, including the COMPANY&apos;s clients. The COMPANY may maintain separate email lists for various purposes. If you want to discontinue receiving electronic mailings from the COMPANY, the &quot;unsubscribe&quot; process of the COMPANY only impacts the future delivery of electronic mailings dispersed by the COMPANY on its own behalf. A Member should remember that unsubscribing from the COMPANY&apos;s emails would not automatically unsubscribe the personal data of the Member from any of the third-party businesses. As third-party businesses separately maintain databases from the COMPANY, a Member will need to unsubscribe from each of the sources individually, if desired.</p>
            <h4>Third-Party Advertising</h4>
            <p>
                The ads served on <span className="domain-name"></span>
                are from third-party advertising. These third-party advertising companies utilize the visitor&apos;s information on the <span className="domain-name"></span>
                and other associated sites that we advertise upon to offer COMPANY ads. Moreover, while our ads are being served to you, the third-party companies would recognize their respective cookies on your browser. To manage our online advertising, we may use Web beacons, which are offered by other third-party advertising companies. These Web beacons help us in recognizing the cookie of a browser when a visitor is on our website and to understand which banner ad has brought the visitor to our website.
            </p>
            <h4>Anonymous Information Collected on This Website</h4>
            <p>
                Anonymous data such as the number of visits, traffic logs, clicks on a given page, pages viewed or links to our site is recorded by us. We utilize cookies and IP addresses for collecting statistics of the website. We also store IP addresses on our server for situations wherein <span className="domain-name"></span>
                should become corrupted and it becomes important to learn the cause of such corruption.
            </p>
            <p>
                We combine this anonymous data as part of maintaining, managing, and reporting on the use of our <span className="domain-name"></span>
                . We may share this anonymous data with third parties for the limited purpose of reporting the use of a <span className="domain-name"></span>
                , or to conform with the applicable law. We do not sell, rent, or share any anonymous data collected on this website with third parties for any marketing activities. This anonymous data is not linked to personally identifiable information and we do not make any attempt to link IP addresses to the individuals who visit this website.
            </p>
            <p>
                We combine this anonymous data as part of maintaining, managing, and reporting on the use of our <span className="domain-name"></span>
                . We may share this anonymous data with third parties for the limited purpose of reporting the use of a <span className="domain-name"></span>
                , or to conform with the applicable law. We do not sell, rent, or share any anonymous data collected on this website with third parties for any marketing activities. This anonymous data is not linked to personally identifiable information and we do not make any attempt to link IP addresses to the individuals who visit this website.
            </p>
            <p>
                Our websites and emails may contain links to external websites (&quot;Linked Sites&quot;) that are not operated or owned by our COMPANY. By clicking on these links, you will be redirected to other websites. Your information may be transferred from the database of the COMPANY to the database of the external websites, for your suitability. You agree and acknowledge that we are not accountable for the privacy actions and practices of such Linked Sites, and this Privacy Policy does not stretch to such Linked Sites. Accordingly, Linked Sites may collect your personal information and disclose the same in numerous ways. Thereafter, we would gather the information on the <span className="domain-name"></span>
                . We insist and encourage you to read the privacy policies on the Linked Sites. THE COMPANY DISCLAIMS ALL LIABILITY OR RESPONSIBILITY FOR THE PRIVACY PRACTICES OF, ACTIONS OF, OR INFORMATION COLLECTED OR CONTAINED VIA LINKED SITES.
            </p>
            <h4>In the Event of Merger or Sale</h4>
            <p>
                <span className="domain-name"></span>
                holds the right to assign or transfer the information collected from users in relation with a corporate transaction, such as a merger, divestiture, asset sale consolidation, or sale, or in the unlikely incident of bankruptcy.
            </p>
        </div>
    </div>
)

export default PrivacyPolicy;