import {Box, Button, Typography} from "@mui/material";
import PCImage1 from "../../assets/images/pc-search.png";
import AddExtButton from "./AddExtButton";

const ChromeExtensionAd = () => (
    <Box sx={{display: "flex", gap: {xs: "1.5rem", sm: "2.25rem"}, maxWidth: {xs: "100%", sm: "450px"}}}>
        <Box sx={{width: {xs: "121px", sm: "152px"}, height: {xs: "92px", sm: "115px"} }}>
            <img src={PCImage1} alt="personal computer" style={{width: "100%"}} />
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", gap: {xs: ".75rem", sm: "1rem"}, maxWidth: {xs: "172px", sm: "258px"}}}>
            <Typography sx={{fontSize: {xs: ".75rem", sm: "1.125rem"}, fontWeight: "600", lineHeight: {xs: "1rem", sm: "1.5rem"}}}>
                Take back your online privacy
                by making Croxy.io your
                default search engine.
            </Typography>
            <AddExtButton />
        </Box>
    </Box>
)

export default ChromeExtensionAd;
