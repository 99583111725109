import {FC, useContext} from "react";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {Box, Button, Typography} from "@mui/material";
import {SearchContext} from "../../../../../../../context/SearchContext";
import VideoPlayIcon from "../../../../../../../assets/images/play.png";
import ErrorFavicon from "../../../../../../../assets/icons/error-fav.svg";

export const paddingActionBtnStyle = {
    textTransform: "none", borderRadius: "1.5rem", color: "var(--blue)",
    background: "var(--padding-btn)", p: ".5rem 1.5rem", fontSize: "14px", fontWeight: "500",
    ":hover": {background: "var(--padding-btn)", color: "var(--blue)"},
}

dayjs.extend(relativeTime)

const Videos: FC<any> = ({ results, isNext }) => {
    const {currentPage, refreshStatesHandler} = useContext(SearchContext);

    const changePageHandler = (side: string = "next") => {
        let value: number = 1;
        if (side === "next") {
            if (isNext) {
                value = currentPage + 1;
            }
        } else if (currentPage > 1) {
            value = currentPage - 1;
        }
        sessionStorage.setItem("p", String(value));
        refreshStatesHandler()
    }

    return (
        <Box sx={{margin: {xs: "152px 0 0 0", sm: "112px 0 0 170px", minHeight: {xs: "calc(100vh - 152px)", sm: "calc(100vh - 112px)"},
            display: "flex", flexDirection: "column", justifyContent: "space-between"}}}
        >
            <Box sx={{p: {xs: "1rem", sm: "1rem 0"}, maxWidth: {xs: "100%", sm: "800px"}, width: "100%"}}>
                <Box sx={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                    { results && results.length > 0 &&
                        results.map((result: any, index: number) => (
                            <Link to={result.content} target="_blank" key={index}>
                                <Box key={index} sx={{display: "flex", gap: {xs: ".5rem", sm: "1rem"}, cursor: "pointer", flexDirection: {xs: "column", sm: "row"}}}>
                                    <Box sx={{width: {xs: "100%", sm: "253px"}, height: {xs: "192px", sm: "148px"}, borderRadius: "1rem",
                                        overflow: "hidden", position: "relative", background: "var(--light-gray)"}}
                                    >
                                        <img src={result.images?.small || result.images?.middle || result.images?.large} alt="video preview" />
                                        <img src={VideoPlayIcon} style={{width: "3rem", height: "3rem", position: "absolute", top: "35%", left: "40%", zIndex: "40"}} alt="play icon"/>
                                        <Typography sx={{position: "absolute", bottom: "4px", right: "2px", borderRadius: ".75rem",
                                            color: "#fff", fontSize: "13px", fontWeight: "500", p: "2px 4px", background: "rgba(0, 0, 0, 0.20)"}}
                                        >
                                            {result.duration}
                                        </Typography>
                                    </Box>
                                    <Box sx={{display: "flex", flexDirection: "column", gap: ".5rem", p: ".5rem 0", maxWidth: "532px"}}>
                                        <Typography sx={{fontSize: "1rem", fontWeight: "500", color: "var(--blue)"}}>
                                            {result.title}
                                        </Typography>
                                        <Typography sx={{display: "-webkit-flex", WebkitLineClamp: "2", fontSize: "13px", WebkitBoxOrient: "vertical",
                                            lineHeight: "1.25rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal", maxHeight: "3em", color: "var(--black)"}}
                                        >
                                            {result.description}
                                        </Typography>
                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                            <img src={ErrorFavicon} style={{width: "1rem", height: "1rem", marginRight: ".25rem"}} alt="web icon" />
                                            <Typography sx={{fontSize: "13px", color: "var(--href)", marginRight: ".5rem"}}>
                                                {result.publisher}
                                            </Typography>
                                            <Typography sx={{fontSize: "13px", color: "var(--href)", marginRight: ".5rem"}}>
                                                {dayjs(result?.published).fromNow()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Link>
                        ))
                    }
                </Box>
                <Box sx={{display: "flex", gap: "1rem", mt: "1rem", p: ".5rem 0", alignItems: "center", justifyContent: {xs: "center", sm: "start"}}}>
                    { currentPage > 1 &&
                        <Button sx={paddingActionBtnStyle}
                            onClick={() => changePageHandler("prev")}
                        >
                            Prev
                        </Button>
                    }
                    <Button sx={{minWidth: "auto", width: "34px", height: "34px", background: "var(--blue)",
                        borderRadius: "50%", color: "#fff", fontSize: "14px",
                        ":hover": {background: "var(--blue)", color: "#fff"},
                    }}>
                        {currentPage}
                    </Button>
                    { isNext &&
                        <Button sx={paddingActionBtnStyle}
                            onClick={() => changePageHandler("next")}
                        >
                            Next
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Videos;